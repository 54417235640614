import { TooltipFormatterContextObject } from "highcharts";
import { Button } from "rsuite";
import { DateTime } from "luxon";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";

import { dateFormatter } from "@/pipes/date";
import { DAYS_LIMIT } from "@/library/constants";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { Patient, PatientCondition } from "@/domain/patient/model/types";
import { ModelCollection } from "@/library/model";
import { NoteModel } from "@/domain/notes/model";
import { Note } from "@/domain/notes/model/types";
import { PatientId } from "@/domain/patient/redux/types";
import { PatientNoteTypes } from "@/domain/notes/model/constants";
import { ObservationType } from "@/domain/observations/types";
import { truncateObservationValue } from "@/domain/observations/helpers";
import { AlertTableRow } from "@/domain/patient/view/AlertsTable/types";
import styles from "./styles.module.scss";

export const parseTooltipDate = (
  observationType: ObservationType,
  tooltipFormatter: TooltipFormatterContextObject,
  valueSuffix?: string,
  seriesName?: string
): string => {
  let observationReading = "";
  if (tooltipFormatter.points?.length) {
    tooltipFormatter.points?.forEach((point) => {
      observationReading +=
        '<tspan style="fill:#7cb5ec">● </tspan>' +
        (point ? point.series.name : seriesName || "") +
        ": <b>" +
        truncateObservationValue(
          observationType,
          point.y,
          valueSuffix === "L"
        ) +
        " " +
        `${valueSuffix ? `(${valueSuffix})` : ""}` +
        "</b><br>";
    });
  } else if (tooltipFormatter.point) {
    observationReading +=
      '<tspan style="fill:#7cb5ec">● </tspan>' +
      (tooltipFormatter.point
        ? tooltipFormatter.point.series.name
        : seriesName || "") +
      ": <b>" +
      truncateObservationValue(observationType, tooltipFormatter.point.y) +
      " " +
      `${valueSuffix ? `(${valueSuffix})` : ""}`;
  }

  return (
    '<tspan style="font-size: 10px">' +
    dateFormatter({
      date: tooltipFormatter.x,
      includeTime: true,
      toLocalTimezone: { enabled: true },
    }) +
    "</tspan > <br/>" +
    observationReading
  );
};

export const getStartDate = (): string => {
  const datetime = convertDateToLocalTimezone(DateTime.now().toJSDate());
  return datetime
    .minus({ days: DAYS_LIMIT - 1 })
    .startOf("day")
    .toISODate();
};

export const getEndDate = (): string => {
  return DateTime.now().endOf("day").toISODate();
};

export const exportPatientDetail = (props: {
  patient: Patient;
  provider?: string;
  practice?: string;
  startDate: Date;
  endDate: Date;
}) => {
  const { patient, provider, practice, startDate, endDate } = props;

  if (!patient || !startDate || !endDate) return "";

  return (
    "<b>Patient Name</b>: " +
    patient.demographics.legalName.firstName +
    " " +
    patient.demographics.legalName.lastName +
    " &nbsp; <b>DOB</b>: " +
    patient.demographics.dateOfBirth +
    " &nbsp; <b>MRN</b>: " +
    patient.mrn +
    " &nbsp; <br><b>Diagnosis</b>: " +
    patient.conditions
      .filter((cond: PatientCondition) => {
        if (
          cond.type === "PRIMARY" ||
          (cond.type === "SECONDARY" && !cond.resolved)
        ) {
          return cond;
        }
      })
      .map((condition: PatientCondition) => condition.displayName)
      .join(", ") +
    "<br><b>Provider</b>: " +
    provider +
    " &nbsp; <b>Practice</b>:  " +
    practice +
    "<br><b>Observation Date Range</b>:  " +
    dateFormatter({ date: startDate.toISOString() }) +
    " to " +
    dateFormatter({ date: endDate.toISOString() }) +
    "<br><b>Exported On</b>: " +
    new Date().toLocaleString()
  );
};

export const getAllNotes = async (patientId: PatientId): Promise<Note[]> => {
  if (!patientId)
    return Promise.reject("Cannot fetch notes. Patient not loaded.");

  const noteCollection: ModelCollection<NoteModel, Note> =
    await NoteModel.syncByPatientId(patientId);
  noteCollection.withParams({
    limit: 499,
    types: [PatientNoteTypes.Observation],
  });
  const noteIterator = await noteCollection.getMany();
  const noteModels = await noteIterator.getAll();

  return noteModels.map((o) => o.pluckAll());
};

export const getUnresolvedNotes = async (
  patientId: PatientId
): Promise<Note[]> => {
  const noteCollection: ModelCollection<NoteModel, Note> =
    NoteModel.syncByPatientId(patientId);
  await noteCollection
    .withParams({
      isManual: false,
      isComplete: false,
      types: [PatientNoteTypes.Assessment, PatientNoteTypes.Observation],
    })
    .fetch();

  return noteCollection.container.map((noteModel) => noteModel.pluckAll());
};

export const renderActions =
  (
    handleAccept: (selection: RowSelectionState) => () => void,
    handleReject: (selection: RowSelectionState) => () => void
  ) =>
  (
    columns: ColumnDef<AlertTableRow, unknown>[],
    rowSelection: RowSelectionState
  ) => {
    const numSelected = Object.keys(rowSelection).length;

    return (
      <tr className={styles.actionsTr}>
        <td colSpan={columns.length}>
          <div className={styles.actions}>
            <div className={styles.actionsLeft}>
              {numSelected ? (
                <>{`${numSelected} note${
                  numSelected === 1 ? "" : "s"
                } selected.`}</>
              ) : null}
            </div>
            <div className={styles.actionsRight}>
              <Button
                className={styles.actionBtn}
                appearance="ghost"
                color="green"
                disabled={!numSelected}
                onClick={handleAccept(rowSelection)}
              >
                Accept
              </Button>
              <Button
                className={styles.actionBtn}
                appearance="ghost"
                color="red"
                disabled={!numSelected}
                onClick={handleReject(rowSelection)}
              >
                Reject
              </Button>
            </div>
          </div>
        </td>
      </tr>
    );
  };

export enum biometryEntryMethod {
  Accessory = "ACCESSORY",
  PatientEntry = "PATIENT_ENTRY",
  ClinicianEntry = "CLINICIAN_ENTRY",
}
export const getBiometryEntryIcon = (source: string) => {
  let entryMethodIcon = " ";
  switch (source) {
    case biometryEntryMethod.PatientEntry:
      entryMethodIcon = '<i class="fa fa-keyboard-o" aria-hidden="true"></i>';
      break;
    case biometryEntryMethod.Accessory:
      entryMethodIcon = '<i class="fa fa-bluetooth-b" aria-hidden="true"></i>';
      break;
    case biometryEntryMethod.ClinicianEntry:
      entryMethodIcon = '<i class="fa fa-user-md"/>';
      break;
    default:
      entryMethodIcon = "";
      break;
  }
  return entryMethodIcon;
};
