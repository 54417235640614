import { ObservationType } from "@/domain/observations/types";
import { BiometricType, GraphElementIds } from "./constants";
import * as htmlToImage from "html-to-image";
import { NoteModel } from "@/domain/notes/model";
import { PaginationConfig } from "@/library/types";
import { PatientNoteType } from "@/library/types/note";

export const convertHtmlToImageUrl = async (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    const url = await htmlToImage.toJpeg(element!);
    return url;
  }
  return element;
};

export const generateGraphImages = async (
  biometricOptionsToInclude?: BiometricType[]
) => {
  const urls = [];
  const headings = [];
  if (biometricOptionsToInclude?.length) {
    if (biometricOptionsToInclude?.includes(BiometricType.HeartRate)) {
      urls.push(
        await convertHtmlToImageUrl(
          `${GraphElementIds[ObservationType.HeartRate]}`
        )
      );
      headings.push(BiometricType.HeartRate);
    }
    if (biometricOptionsToInclude?.includes(BiometricType.BloodPressure)) {
      urls.push(
        await convertHtmlToImageUrl(
          `${GraphElementIds[ObservationType.BloodPressure]}`
        )
      );
      headings.push(BiometricType.BloodPressure);
    }
    if (biometricOptionsToInclude?.includes(BiometricType.PulseOximetry)) {
      urls.push(
        await convertHtmlToImageUrl(
          `${GraphElementIds[ObservationType.PulseOximetry]}`
        )
      );
      headings.push(BiometricType.PulseOximetry);
    }
    if (biometricOptionsToInclude?.includes(BiometricType.Weight)) {
      urls.push(
        await convertHtmlToImageUrl(
          `${GraphElementIds[ObservationType.Weight]}`
        )
      );
      headings.push(BiometricType.Weight);
    }
    if (biometricOptionsToInclude?.includes(BiometricType.GlucoseLevel)) {
      urls.push(
        await convertHtmlToImageUrl(
          `${GraphElementIds[ObservationType.GlucoseLevel]}`
        )
      );
      headings.push(BiometricType.GlucoseLevel);
    }
    if (biometricOptionsToInclude?.includes(BiometricType.Temperature)) {
      urls.push(
        await convertHtmlToImageUrl(
          `${GraphElementIds[ObservationType.Temperature]}`
        )
      );
      headings.push(BiometricType.Temperature);
    }
    if (biometricOptionsToInclude?.includes(BiometricType.Spirometry)) {
      urls.push(
        await convertHtmlToImageUrl(
          (
            GraphElementIds[ObservationType.Spirometry] as {
              pef: string;
              fev1: string;
            }
          ).pef
        )
      );
      headings.push(BiometricType.Spirometry);
      urls.push(
        await convertHtmlToImageUrl(
          (
            GraphElementIds[ObservationType.Spirometry] as {
              pef: string;
              fev1: string;
            }
          ).fev1
        )
      );
      headings.push(BiometricType.Spirometry);
    }
  } else {
    urls.push(
      await convertHtmlToImageUrl(
        `${GraphElementIds[ObservationType.HeartRate]}`
      )
    );
    urls.push(
      await convertHtmlToImageUrl(
        `${GraphElementIds[ObservationType.BloodPressure]}`
      )
    );
    urls.push(
      await convertHtmlToImageUrl(
        `${GraphElementIds[ObservationType.PulseOximetry]}`
      )
    );
    urls.push(
      await convertHtmlToImageUrl(`${GraphElementIds[ObservationType.Weight]}`)
    );
    urls.push(
      await convertHtmlToImageUrl(
        `${GraphElementIds[ObservationType.GlucoseLevel]}`
      )
    );
    urls.push(
      await convertHtmlToImageUrl(
        `${GraphElementIds[ObservationType.Temperature]}`
      )
    );
    urls.push(
      await convertHtmlToImageUrl(
        (
          GraphElementIds[ObservationType.Spirometry] as {
            pef: string;
            fev1: string;
          }
        ).pef
      )
    );
    urls.push(
      await convertHtmlToImageUrl(
        (
          GraphElementIds[ObservationType.Spirometry] as {
            pef: string;
            fev1: string;
          }
        ).fev1
      )
    );
  }
  return [headings, urls.filter((url) => url)];
};

export const fetchPatientNotes = async (
  patientId: string,
  startDate: string,
  endDate: string
) => {
  const noteCollection = NoteModel.syncByPatientId(patientId);
  // only parent notes should be fetched
  const paginationConfig: PaginationConfig = await noteCollection
    .withParams({
      limit: 100,
      patientId,
      startDate,
      endDate,
      includeRepliedTo: true,
      types: [PatientNoteType.Clinical],
    })
    .fetch();

  const notes = (noteCollection.container || []).map((note: NoteModel) =>
    note.pluckAll()
  );
  return notes;
};
