import React, { useState } from "react";
import {
  BiometricType,
  getCurrentDate,
  getStartDate,
  SummaryDateRange,
  SummaryNoteOptions,
} from "./constants";
import { SummaryDateRangePicker } from "./SummaryDateRange";
import styles from "./styles.module.scss";
import BiometricOptions from "./BiometricOptions";
import ChartNoteOptions from "./ChartNoteOptions";
import CreateSummary from "./CreateSummary";
import ProviderComment from "./ProviderComment";
import { AggregateTable } from "@/domain/patient/view/tables/AggregateTable";
import { useSelector } from "react-redux";
import { selectAggregateTableData } from "@/domain/patient/view/tables/AggregateTable/selectors";

export const PatientSummary: React.FC = () => {
  const [selectedDateRange, setSelectedDateRange] = useState<SummaryDateRange>(
    SummaryDateRange.Last14Days
  );
  const [includeCharts, setIncludeCharts] = useState<boolean>(true);
  const [includeTables, setIncludeTables] = useState<boolean>(true);
  const [biometricOptionsToInclude, setBiometricOptionsToInclude] = useState(
    [] as BiometricType[]
  );
  const [selectedNoteOption, setSelectedNoteOption] =
    useState<SummaryNoteOptions>(SummaryNoteOptions.All);
  const [startDate, setStartDate] = useState<Date>(getStartDate());
  const [endDate, setEndDate] = useState<Date>(getCurrentDate);
  const [providerComment, setProviderComment] = useState<string>("");
  const handleReset = () => {
    setSelectedDateRange(SummaryDateRange.Last14Days);
    setIncludeCharts(true);
    setIncludeTables(true);
    setSelectedNoteOption(SummaryNoteOptions.All);
    setStartDate(getStartDate());
    setEndDate(getCurrentDate);
  };

  const addBiometricOptions = (biometricType: BiometricType) => {
    if (!biometricOptionsToInclude.includes(biometricType))
      setBiometricOptionsToInclude([
        ...biometricOptionsToInclude,
        biometricType,
      ]);
  };
  const removeBiometricOptions = (biometricType: BiometricType) => {
    const newBiometricOptions = biometricOptionsToInclude.filter(
      (type: BiometricType) => type !== biometricType
    );
    setBiometricOptionsToInclude(newBiometricOptions);
  };

  return (
    <div className="container">
      <div className={styles.summaryTabContent}>
        <div className="row mt-5">
          <div className="col-md-4">
            <SummaryDateRangePicker
              setSelectedDateRange={setSelectedDateRange}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              selectedDateRange={selectedDateRange}
            />
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-12">
                <BiometricOptions
                  includeCharts={includeCharts}
                  setIncludeCharts={setIncludeCharts}
                  includeTables={includeTables}
                  setIncludeTables={setIncludeTables}
                  addBiometricOptions={addBiometricOptions}
                  removeBiometricOptions={removeBiometricOptions}
                />
              </div>
              <div className="col-12">
                <ChartNoteOptions
                  selectedNoteOption={selectedNoteOption}
                  setSelectedNoteOption={setSelectedNoteOption}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <ProviderComment
              providerNote={providerComment}
              setProviderNote={setProviderComment}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-5">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleReset}
          >
            Restore Defaults
          </button>
          <CreateSummary
            startDate={startDate}
            endDate={endDate}
            selectedNoteOption={selectedNoteOption}
            includeCharts={includeCharts}
            includeTables={includeTables}
            providerNote={providerComment}
            biometricOptionsToInclude={biometricOptionsToInclude}
          />
        </div>
      </div>
    </div>
  );
};
